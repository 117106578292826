@import 'bs/bootstrap';

body {
  font-family: 'Montserrat', sans-serif;
}

a {
  transition: color .25s ease-in-out;
  span {
    transition: color .25s ease-in-out;
  }
}

a:hover {
  text-decoration: none;
  color: #fff;
  span {
    color: #fff !important;
  }
}

$pink: #f53e82;
$hightlight2: #681b8a;

.thin {
	font-weight: 100 !important;
}

.xlight {
	font-weight: 200 !important;
}

.light {
	font-weight: 300 !important
}

.regular {
	font-weight: 400 !important;
}

.medium {
	font-weight: 500 !important;
}

.sbold {
	font-weight: 600 !important;
}

.bold {
	font-weight: 700 !important;
}

.xbold {
	font-weight: 800 !important;
}

.black {
	font-weight: 900 !important;
}

// light 300
// regular 400
// medium 500
// semi-bold 600
// bold 700
// extra-bold 800
// black 900

@include media-breakpoint-up(md) {
  .container {
    max-width: 1440px !important;
  }
}

.panel-login {

  .text-muted {
    font-size: 14px;
    line-height: 18px;
  }

  .content-login {
    height: 100%;
    min-height: 100vh;
    // background-image: url('../img/background.jpg');
    background-repeat: no-repeat;
    // background-size: 130% auto;
    background-image: url('../img/background.svg');
    background-size: 2560px 1440px;
    background-position: center;
    // @extend .py-5;
    @extend .d-flex;

    &.login-artist {
      background-image: url('../img/background-artist.jpg');
      background-size: cover;
      background-position: center;

      h1 {
        color: #ffffff;
      }

      .logo-dark {
        background-image: url('../img/logo-white-artists.png');
        background-repeat: no-repeat;
        width: 220px;
      }
    }

    color: #fff;

    .logo-dark {
      background-image: url('../img/logo-black.png');
      background-repeat: no-repeat;
      background-size: 100%;
      width: 120px;
      height: 48px;
      display: block;
    }
  }

  a {
    color: #b0b5d0;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      color: $pink;
    }
  }

  .logo-white {
    background-image: url('../img/logo-white.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 176px;
    height: 70px;
    display: block;
  }

  .btn {
    border-radius: 4px;
    background-image: linear-gradient( 0deg, rgb(104,27,138) 0%, rgb(245,62,130) 50%, rgb(245,62,130) 100%);
    background-size: 100% 200%;
    background-position: bottom center;
    box-shadow: 0px 20px 50px 0px rgba(104, 27, 138, 0.35);
    border: none;
    padding: 8px 43px;
    font-size: 14px !important;
    color: rgb(255, 255, 255);
    @extend .sbold;
    line-height: 36px;
    text-align: center;

    transition: all .25s ease-in-out;

    &:hover {
      background-position: top center;
      transform: translateY(-5px);
      color: #ffffff;
    }

    &.btn-outline {
      background: transparent;
      box-shadow: none;
      color: $pink;
      border: solid 1px rgba(177, 180, 196, 0.3);
    }
  }


  .navbar {

    margin: 50px 0;
    padding: 10px 0;

    .nav-item {
      @extend .mx-2;
      .nav-link {
        font-size: 14px;
        color: rgb(130, 138, 183);
        line-height: 36px;
        @extend .medium;
        transition: all .25s ease-in-out;
        &:hover {
          color: $pink;
        }
      }
    }

    .navbar-nav.whiteText {
      .nav-link {
        color: #ffffff;
        &:hover {
          color: $hightlight2;
        }
      }
    }

    .nav.social {
      // position: absolute;
      // top: 0;
      // right: 0;
      // transform: translateY(-50%);
      .nav-item {
        margin: 0 !important;
      }
      .nav-link {
        font-size: 24px;
        color: #ffffff;
        &:hover {
          color: $hightlight2;
        }
      }
    }

    .dropdown-menu {
      top: 45px;
      left: -30px;
      border-radius: 2px;
      background-color: rgb(28, 27, 45);
      box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.2);
      padding: 15px 30px;
      min-width: 12rem;

      .dropdown-item {
        @extend .sbold;
        color: rgb(137, 137, 165);
        font-size: 14px;
        font-weight: bold;
        padding: 0;
        transition: color .25s ease-in-out;
        padding: 12px 0;
        &:hover, &:active, &:focus {
          color: $pink;
          background: none;
        }
      }
    }
  }

  .dropdown {
    border-radius: 4px;
    button, button:hover, button:active {
      background: transparent;
      box-shadow: none;
      color: #917fa6;
      text-align: left;
      padding-left: 0;
      transform: none;
    }

    transition: border-color 0.15s ease-in-out;
    &.show {
      border-color: #f53e82;
    }
  }

  ul.inline {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      margin-right: 28px;
      padding: 0;
      color: #ccc1d8;
    }
  }

  h1 {
    font-size: 48px;
    @extend .xbold;
    line-height: 83px;
    color: #000000;
  }

  h3 {
    font-size: 30px;
    font-weight: 300;
    line-height: auto;
  }

  .button {
    border: 3px solid rgba(42, 41, 71, .1);
    border-radius: 100px;
    background-clip: padding-box;

    padding: 14px 21px;
    text-transform: uppercase;

    opacity: .6;
    color: #7c7b91 !important;
    font-size: 10px !important;
    font-weight: 700 !important;

    display: inline-block;

    transition: all .25s ease-in-out;

    position: relative;
    z-index: 0;

    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;

    i {
      font-size: 14px;
      margin-right: 10px;
    }

    &.button-f {
      background: #f53e82;
      color: #ffffff !important;
      border: none;
      opacity: 1;
      padding: 14px 21px;

      &.facebook {
        background: #475993 !important;
        &:before {
          background: #475993 !important;
          box-shadow: 0 15px 30px rgba(71, 89, 147, .5) !important;
        }
      }
      &.twitter {
        background: #75a9e9 !important;
        &:before {
          background: #75a9e9 !important;
          box-shadow: 0 15px 30px rgba(118, 169, 234, .5) !important;
        }
      }
      &.google {
        background: #ff4b25 !important;
        &:before {
          background: #ff4b25 !important;
          box-shadow: 0 15px 30px rgba(255, 75, 38, .5) !important;
        }
      }
      &.civic {
        background: #39af3d !important;
        &:before {
          background: #39af3d !important;
          box-shadow: 0 15px 30px rgba(58, 176, 62, .5) !important;
        }
      }
    }

    &:before {
      background-color: #f53e82;
      background: linear-gradient(0deg, rgba(104, 27, 138, 0.2) 0%, rgba(245, 62, 130, 0.2) 100%), #f53e82;
      box-shadow: 0px 15px 30px 0px rgba(245, 62, 130, 0.35);
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -100;
      border-radius: 100px;
      opacity: 0;
      transition: all .25s ease-in-out;
    }

    &:hover {
      text-decoration: none;
      color: #ffffff !important;
      opacity: 1;
      border-color: transparent !important;
      &:before {
        opacity: 1;
      }
    }
  }

  .panel {
    // border-radius: 6px;
    // background-clip: padding-box;
    // background-color: rgba(32, 19, 47, .9);
    // box-shadow: 0 80px 120px rgba(27, 18, 34, .7);
    // padding: 43px;

    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 40px 79.2px 0.8px rgba(80, 83, 97, 0.15);
    padding: 60px;

    form {
      .form-group {
        @extend .d-flex;
        @extend .flex-row;
        @extend .justify-content-between;
        @extend .align-items-center;
        margin-bottom: 31px;
        position: relative;

        &.withError {
          @extend .flex-column;

          .invalid-feedback {
            color: #f53e82;
            padding: 0 20px;
            display: block;
            position: absolute;
            left: 0;
            bottom: -21px;
            white-space: nowrap;
          }
        }

        span {
          color: #706083;
          font-size: 9px;
          font-weight: 600;
        }

        i[class="icon-camera"] {
          position: absolute;
          right: 20px;
          font-size: 16px !important;
          color: #bdafd1;
          top: 50%;
          transform: translateY(-50%);
        }
        input:checked + label:after {
          // background: red;
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          // display: inline-block;
          text-decoration: inherit;
          text-align: center;
          opacity: .8;
          font-variant: normal;
          text-transform: none;
          // line-height: 1em;
          font-size: 120%;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
          content: '\e817';
          color: $pink;
          position: absolute;
          top: 0;
          left: 1px;
        }

        input:disabled {
          opacity: 0.5;
        }

        &.social {
          .button {
            width: 100%;
            @extend .justify-content-center;
          }
        }
      }
      .button {
        border-color: rgba(91, 67, 120, .5);
      }

      label {
        color: #beaed1;
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;
        margin: 0 20px;
        position: relative;

        &.col-form-label {
          margin: 0;
          padding-left: 35px;
        }

        &.form-check-label {
          font-weight: 500;
          line-height: 26px;
          color: #beaed1;
          margin: 0;

          &:before {
            content: "";
            display: inline-block;
            width: 14px;
            height: 14px;
            border: 2px solid rgba(91, 67, 120, .5);
            border-radius: 5px;
            background-clip: padding-box;
            margin-right: 11px;
            margin-bottom: -3px;
          }
        }

        &.form-radio-label {
          &::before {
            border-radius: 50%;
          }

          cursor: pointer;

          &::after {
            content: '' !important;
            background-color: #f53e82;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            top: 50% !important;
            left: 3px !important;
            transform: translateY(-50%);
          }
        }
      }

      input {
        color: #8c93bc !important;
        font-size: 14px !important;
        line-height: 14px !important;
        font-weight: 500 !important;
        background: transparent !important;
        border-style: solid;
        border-width: 1px;
        border-color: rgba(177, 180, 196, 0.3);
        border-radius: 4px;
        background-clip: padding-box;
        padding: 20px 24px !important;
        &:required + label {
          position: absolute !important;
          right: 0 !important;
          top: 50% !important;
          transform: translateY(-50%) !important;
          &:after {
            content: '' !important;
            width: 5px !important;
            height: 5px !important;
            border-radius: 5px !important;
            background-color: $pink !important;
            display: block !important;
          }
        }
      }

      .col-8 > input:required + label {
        right: 15px !important;
      }

      input::placeholder {
        color: #917fa6;
      }

      input:invalid {
        box-shadow: none;
        outline: none;
      }

      input:focus {
        outline: none;
        box-shadow: none;
        background: transparent;
        border-color: $pink;
        color: $pink !important;
      }
    }

    .hr {
      width: 100%;
      margin-top: 43px;
      margin-bottom: 43px;
      color: #5a4c6b;
      font-size: 9px;
      font-weight: 700;
      white-space: nowrap;
      @extend .d-flex;
      @extend .flex-row;
      @extend .align-items-center;
      &:before, &:after {
        content: '';
        @extend .d-flex;
        @extend .flex-row;
        @extend .align-items-center;
        border-top: solid 1px;
        border-color: rgba(112, 97, 131, .35);
        width: 50%;
      }
      &[data-text="true"]:before {
        margin-right: 14px;
      }
      &[data-text="true"]:after {
        margin-left: 14px;
      }
    }
  }

  div.alert2 {
    border-radius: 6px;
    background: rgb(245, 62, 130) !important;
    box-shadow: 0px 20px 40px 0px rgba(245, 62, 130, 0.5) !important;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    border: none;
    @extend .my-4;
    padding: 18px;
    i {
      @extend .float-right;
      color: #1a1a2a;
      font-size: 13px;
    }
  }

}

// end panel-login

.panel-register-artist {

  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 80%), url('../img/background.jpg');
  background-repeat: no-repeat;
  background-size: auto, 130% auto;

  min-height: 100vh;

  .col-12 {
    min-height: 100vh;
  }

  .logo-dark {
    background-image: url('../img/logo-black.png');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 120px;
    height: 48px;
    display: block;
  }

  .form-group {
    margin-bottom: 31px;
    position: relative;

    &.withError {
      @extend .flex-column;

      .invalid-feedback {
        color: #f53e82;
        padding: 0 30px;
        display: block;
        position: absolute;
        left: 0;
        bottom: -21px;
      }
    }

    .form-control, .custom-select {
      border-style: solid;
      border-width: 1px;
      border-color: rgba(70, 69, 94, .1);
      border-radius: 30px;
      padding: 17px 30px;
      font-size: 14px;
      color: rgb(98, 96, 135);
      font-weight: 600;
      line-height: 14px;
      outline: none;
      box-shadow: none;
    }

    .custom-select {
      height: 50px;
      padding: 0 30px;
    }

    span {
      font-size: 14px;
      color: rgb(145, 144, 169);
      line-height: 14px;
    }

    a {
      font-size: 14px;
      color: $pink;
      line-height: 14px;
      font-weight: 600;
      transition: color .25s ease-in-out;
      &:hover {
        color: rgb(145, 144, 169);
      }
    }
  }

  ul.inline {
    li {
      margin-right: 40px;
    }

    a {
      font-size: 14px;
      color: rgb(146, 145, 170);
      font-weight: 600;
      transition: color .25s ease-in-out;

      &:hover {
        color: $pink;
      }
    }
  }

  h6 {
    font-size: 14px;
    color: rgb(245, 62, 130);
    font-weight: 800;
    line-height: 36px;
    text-transform: uppercase;
    margin: 0;
  }

  h2 {
    font-size: 24px;
    color: rgb(255, 255, 255);
    line-height: 36px;
    font-weight: 300;
    text-align: center;
  }
}