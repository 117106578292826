#CookieComponent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f13b82;
  background-image: linear-gradient(90deg, #f13b82, #b52a85);
  z-index: 9999;
  padding: 25px;

  display: flex;
  align-items: center;

  p {
    color: #ffffff;
    font-size: 10px;
    line-height: 16px;
  }

  button {
    flex-grow: 1;
    padding-left: 30px;
    padding-right: 30px;
    color: #ffffff;
    outline: none;
  }

  a {
    color: darken($color: #ffffff, $amount: 15%);

    &:hover {
      color: #681b8a;
    }
  }
}