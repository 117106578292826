@import 'bs/bootstrap';

body {
	font-family: 'Montserrat', sans-serif;
	// overflow: hidden;
}

a {
    outline: 0;
    cursor: pointer;
}

a:hover {
	text-decoration: none;
}

$cover_shadow: 0px 20px 20px 0px rgba(255,255,255,.3);
$pink: #f53e82;

.menu-container {
	background-color: #fbfbfd;
	background-image: url('../img/menubg.png');
	background-repeat: no-repeat;
	background-position: top right;
	// overflow-y: auto;
	// overflow: hidden;
	height: 100vh;
	padding: 30px !important;

	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	overflow-y: auto;

	span {
		@extend .my-4;
		opacity: .4;
		color: #4f4e6c;
		font-size: 12px;
		font-weight: 700;
	}
}

.thin {
	font-weight: 100 !important;
}

.xlight {
	font-weight: 200 !important;
}

.light {
	font-weight: 300 !important
}

.regular {
	font-weight: 400 !important;
}

.medium {
	font-weight: 500 !important;
}

.sbold {
	font-weight: 600 !important;
}

.bold {
	font-weight: 700 !important;
}

.xbold {
	font-weight: 800 !important;
}

.black {
	font-weight: 900 !important;
}

.text-wrap {
	word-wrap: break-word;
}

.hiddenText {
    color: transparent !important;;
    text-shadow: 0 0 6px rgba(0, 0, 0, .5) !important;
    user-select: none !important;
    -moz-user-select: -moz-none !important;
}

.search {
	@extend .my-4;
	border: solid 2px rgba(0, 0, 0, .08);
	border-left: none;
	border-right: none;
	padding: 25px 0;
	form {
		@extend .d-flex;
		i.icon-search {
			font-size: 14px;
			color: $pink;
			margin-right: 10px;
		}
		input {
			@extend .w-100;
			background: transparent;
			color: #4f4e6c;
			font-size: 15px;
			font-weight: 500;
			border: none;
			padding: 0;
			margin: 0;
			&::placeholder {
				color: #4f4e6c;
				opacity: 1;
			}
		}
	}
}

#searchContainer {
	// display: none;
	form {
		@extend .d-flex;
		@extend .my-4;
        position: relative;
        height: 70px;
		i.icon-search {
			font-size: 22px;
			color: $pink;
			margin-right: 10px;
		}
		input {
			@extend .w-100;
			background: transparent;
			color: #4f4e6c;
			font-size: 22px;
			font-weight: 400;
			border: none;
			padding: 0;
			margin: 0;
			z-index: 10;
			caret-color: $pink;
			&::placeholder {
				color: #4f4e6c;
				opacity: 1;
			}
		}
		*:focus {
			outline: none;
			box-shadow: none;
			outline-width: 0;
		}
		&:before {
			z-index: 0;
			color: #b3b3c2;
			font-size: 22px;
			font-weight: 400;
			content: attr(data-prompt);
			position: absolute;
			top: 0;
			left: 41px;
		}
	}

	.jumbtron {
		margin: 0 !important;
		background: none;
		box-shadow: none;
		.wrapper {
			padding: 0 !important;
			background: none;
		}

		h3 {
			color: #44435e !important;
		}

		span {
			color: #9190aa !important;
		}

		.button {
			border-color: rgba(0, 0, 0, .1);
		}
	}
}

// .body-container {
// 	max-width: 1410px;
// 	@extend .mx-auto;
// }

.maxwidth {
	max-width: 1410px;
	@extend .mx-auto;
}

.contentBox{
	// overflow: hidden !important;
	// height: 100vh;
	min-height: 100vh !important;
	@extend .ml-auto;
	// max-width: 1410px;
	// @extend .mx-auto;
}

ul.menu {
    @extend .my-4;
    padding: 0;
    border: none;

    li {
        list-style-type: none;
        padding: 0px 0;

        a {
            cursor: pointer;
            color: #4f4e6c;
            font-size: 15px;
            font-weight: 500;
            transition: all .25s ease-in-out;
            width: 100%;
            display: inline-block;
            position: relative;
            padding: 10px 0;

            &:hover {
                text-decoration: none;
                color: #27263a;

                i {
                    color: #c7c7d1;
                }
            }

            i {
                color: #dcdce1;
                margin-right: 15px;
                font-size: 14px;
                transition: all .25s ease-in-out;
            }
        }

        a.active {
            color: #671b89;

            i {
                color: $pink;
            }
        }
    }

    &.dropdown {
        li.dropdown-wrap {
            @extend .d-flex;
            @extend .align-items-center;

            a {
                @extend .w-100;
                color: #4f4e6c;
                font-size: 15px;
                font-weight: 600;
                padding-left: 15px;
            }

            i {
                color: rgba(41, 41, 71, .3);

                &.icon-arrow-down {
                    transform: rotateX(0deg);
                    transition: transform .25s ease-in-out;
                }
            }

            &.wrapped {
                i.icon-arrow-down {
                    transform: rotateX(180deg);
                }
            }
        }

        ul.dropdown-content {
            padding-left: 35px;
            a {
                padding: 5px 0;
                color: #9a99a9;
                font-size: 13px;
                font-weight: 500;
                white-space: nowrap;
                @extend .d-flex;

                i {
                    margin-right: 7.5px;
                }

                & > div {
                    display: inherit;
                    overflow: hidden;
                    & > div {
                        line-height: 24px;
                    }
                }
            }
        }
    }
}

ul.inline {
	@extend .d-flex;
	margin: 0;
	padding: 0;
	li {
		list-style-type: none;
		display: inline-block;
	}
}

small {
	font-size: 10px;
	color: #a8a7be;
}

.button {
    border: 3px solid rgba(42,41,71,.1);
    border-radius: 100px;
    background-clip: padding-box;
    padding: 12px 17px;
    text-transform: uppercase;
    opacity: .6;
    color: #7c7b91 !important;
    font-size: 10px !important;
    font-weight: 700 !important;
    display: inline-block;
    transition: all .25s ease-in-out;
    position: relative;
    z-index: 0;
    cursor: pointer;

    &.button-ico {
        width: 45px;
        height: 45px;
        [class^="icon-"] {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &.button-f {
        background: #f53e82;
        color: #ffffff !important;
        border: none;
        opacity: 1;
        padding: 11.5px 21.5px;

        &:disabled {
            filter: grayscale(1);
            opacity: .5;
        }
    }

    &.button-noborder {
        border: none;
        opacity: 1;

        i[class^="icon-"] {
            color: $pink;
            transition: all .25s ease-in-out;
        }

        &:hover {
            i[class^="icon-"] {
                color: #ffffff;
            }
        }
    }

    &:before {
        background-color: #f53e82;
        background: linear-gradient(0deg, rgba(104, 27, 138, 0.2) 0%, rgba(245, 62, 130, 0.2) 100%), #f53e82;
        box-shadow: 0px 15px 30px 0px rgba(245, 62, 130, 0.35);
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -100;
        border-radius: 100px;
        opacity: 0;
        transition: all .25s ease-in-out;
    }

    &:hover {
        text-decoration: none;
        color: #ffffff !important;
        opacity: 1;
        border-color: transparent !important;
        cursor: pointer;

        &:before {
            opacity: 1;
        }
    }

    input[type=file] {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
    }
}

.alert {
	@extend .d-flex;
	@extend .align-items-center;
	@extend .justify-content-between;
	@extend .p-4;
	// @extend .my-4;
	border: none;
	background: #f53e82;
	box-shadow: 0px 20px 40px 0px rgba(121, 31, 137, 0.3);
	border-radius: 6px;
	color: #fff;
	font-size: 16px;
    font-weight: 600;

    .button.button-f {
        background: #2a2947;

        &:before {
            background: #2a2947;
            box-shadow: 0px 15px 30px 0px #2a2947;
        }
    }
}

.earnings {
	// @extend .mt-5;
	@extend .my-5;
}

.info {
	@extend .d-flex;
	@extend .align-items-center;

	h1 {
		color: #f43d81;
		font-size: 60px;
		font-weight: 900;
		text-align: left;
		text-shadow: 0px 30px 30px rgba(107, 28, 138, 0.2);
		position: relative;
		margin-right: 15px;
	}

	h2 {
		color: #4f4d6b;
		font-size: 18px;
		font-weight: 400;
		line-height: 35px;
		text-align: left;
		margin-left: 15px;

	}

	&.info2 {
		h1 {
			color: #b12d86;
		}
	}
}

a.favorite {
	@extend .d-table-cell;
	vertical-align: middle;
	color: #c8c7d0 !important;
	text-align: center;
	transition: color .25s ease-in-out;
	i.icon-star-full {
		margin-left: -22.4px;
		opacity: 0;
		transition: opacity .25s ease-in-out;
	}
	&:hover {
		// color: #b12d86;
	}

	&.active {
		color: #f53e82 !important;
		i.icon-star-full {
			opacity: 1;
		}
	}
}

.list {
    @extend .mt-5;

    .dropdown {
        border: none !important;
        padding: 0px
    }

    .header {
        @extend .mb-4;
        @extend .d-flex;
        @extend .align-items-center;
        @extend .justify-content-between;

        h2 {
            @extend .d-flex;
            @extend .align-items-center;
            color: #4f4e6c;
            font-size: 24px;
            font-weight: 300;
            margin: 0;
            padding: 0;

            i {
                font-size: 14px;
                color: #c1c1ca;
            }
        }

        ul.switch {
            @extend .d-flex;
            @extend .align-items-center;
            @extend .justify-content-between;

            li {
                list-style-type: none;
                border-radius: 50%;
                border-color: #D3D3D8;
                border-width: 2px;
                border-style: solid;
                width: 11px;
                height: 11px;
                margin-left: 9px;
                cursor: pointer;

                &.active {
                    border-width: 0px;
                    background: #f53e82;
                    box-shadow: 0px 5px 15px 0px rgba(245, 62, 130, 0.5);
                    width: 13px;
                    height: 13px;
                    margin-left: 7px;
                }
            }
        }

        ul.inline {
            li {
                margin-left: 15px;

                a {
                    color: #bebec7;
                    font-size: 12px;
                    font-weight: 700;
                    @extend .text-uppercase;

                    &.active {
                        color: $pink;
                    }

                    &.onlyIcon > i[class^="icon-"] {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .searchbox {
        @extend .mt-5;

        // div:last-of-type {
        //     @extend .text-right;
        //     @extend .d-flex;
        //     @extend .align-items-center;
        //     @extend .justify-content-end;
        // }

        div.noborder {
            border: none;
        }

        .input-group {
            border: 1px solid rgba(42,41,71,.08);
            border-radius: 20px;
            padding: 0 20px;

            input {
                border: none;
                color: #9190aa;
                font-size: 10px;
                font-weight: 600;
                box-shadow: none;
                padding: 12px 0;
            }

            i[class^="icon-"] {
                color: #9191a9;
                font-size: 14px;
                line-height: 36px;
                margin-right: 10px;
            }
        }

        span {
            color: #9998b7;
            font-size: 14px;
            font-weight: 600;
            @extend .mr-2;
        }

        a {
            color: #7c7b9b;
            font-size: 14px;
            font-weight: 500;
        }
    }

    &.list-horizontal {
        .content {
            overflow: hidden;
            // overflow-y: auto;
            padding: 0 20px;
            margin: 0 -20px;
        }

        .wrapper {
            @extend .d-flex;
            width: 40000px;
            transition: margin 1s ease-in-out;
            margin-left: -15px;
        }

        .element {
            display: inline;
            width: 160px;
            // max-width: 160px;
            margin: 0 15px;

            &:first-of-type {
                // margin-left: 0;
            }

            &:last-of-type {
                // margin-right: 0;
            }

            .cover {
                position: relative;
                border-radius: 6px;
                box-shadow: $cover_shadow;
                margin-bottom: 30px;
                max-width: 210px;
                min-width: 150px;
                background: url("/img/placeholder-square.png");
                background-size: contain;
                /*overflow: hidden;*/

                &:before {
                    display: block;
                    content: '';
                    width: 100%;
                    padding-top: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 6px;
                }

                .hoverContent {
                    @extend .d-flex;
                    @extend .align-items-center;
                    @extend .justify-content-around;
                    @extend .px-3;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 6px;
                    opacity: 0;
                    transition: all .25s ease-in-out;
                    background-color: rgba(245, 62, 130, .9);
                    font-size: 16px;

                    a, .dropdown {
                        color: #ffffff;
                        &.favorite {
                            color: #ffffff !important;
                        }
                    }

                    .dropdown {
                        padding: 0;
                        border: none;
                        [class^="icon-"] {
                            color: #fff;
                        }
                    }

                    .icon-play {
                        font-size: 20px;
                    }
                }
            }

            span.title {
                color: #b9b8ce;
                font-size: 12px;
                font-weight: 700;
                display: block;
                margin: 16px 0;
                text-transform: uppercase;
            }

            h3 {
                color: #4f4e6c;
                font-size: 14px;
                line-height: 14px;
                font-weight: 500;
                line-height: 16px;
                margin: 16px 0;
                transition: all .25s ease-in-out;
            }

            span.artist {
                color: #9796b3;
                font-size: 14px;
                font-weight: 500;
                margin: 16px 0;
                transition: all .25s ease-in-out;
            }

            &.artist {
                @extend .text-center;

                .cover {
                    border-radius: 50%;

                    img {
                        border-radius: 50%;
                    }
                }

                .hoverContent {
                    border-radius: 50%;
                }
            }

            &.playlist {
                .cover {
                    border-radius: 26px;

                    img {
                        border-radius: 26px;
                    }
                }

                .hoverContent {
                    border-radius: 26px;
                }
            }

            &:hover {

                .hoverContent {
                    opacity: 1;
                }

                h3 {
                    color: #681b8a;
                }

                span.artist {
                    color: #b12d86 !important;
                }
            }
        }
    }

    &.list-vertical {
        .scroll-element.scroll-x {
            display: none !important;
        }

        & > .content {
            z-index: 100;
            max-height: 430px;
            overflow-y: auto;
            margin: 0 -30px;
        }

        .wrapper {
            @extend .d-table;
            width: 100%;
            padding: 20px 10px;
        }

        &.bordered .element > * {
            border-bottom: solid 1px rgba(0, 0, 0, .05);
        }

        .element {
            width: 100%;
            @extend .d-table-row;
            height: 55px;
            margin: 15px 0;
            transition: box-shadow .25s ease-in-out;

            span {
                color: #9795b3;
                font-size: 14px;
                font-weight: 500;

                &:first-of-type {
                    @extend .text-center;
                    position: relative;

                    &:not(.coverContent) {
                        /*width: 40px;*/
                        /*text-align: right !important;*/
                    }

                    a {
                        @extend .d-flex;
                        @extend .align-items-center;
                        @extend .justify-content-center;
                        background: #ffffff;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 32px;
                        height: 32px;
                        color: rgba(121, 123, 139, .45);
                        border: 3px solid rgba(131,133,149,.15);
                        border-radius: 32px;
                        opacity: 0;
                        transition: all .25s ease-in-out;
                        font-size: 8px;

                        i {
                            margin-left: .8px;
                        }

                        &:hover {
                            border-color: transparent;
                            background-color: $pink;
                            color: #fff;
                        }
                    }
                }

                &:last-of-type {
                    @extend .text-center;
                }

                &:nth-last-child(2) {
                    @extend .text-right;
                }
            }

            a:hover > span {
                color: #292947 !important;
            }

            span, h3 {
                @extend .d-table-cell;
                vertical-align: middle;
                transition: all .25s ease-in-out;
            }

            a {
                display: table-cell;
                vertical-align: middle;
                width: 32%;

                span {
                    width: 100%;
                }
            }

            .coverContent {
                @extend .d-table-cell;
                width: 80px;
                height: 80px;
                padding-left: 20px;
                vertical-align: middle;
            }

            .cover {
                // @extend .d-table-cell;
                // vertical-align: middle;
                position: relative;
                width: 60px;
                border-radius: 6px;
                box-shadow: $cover_shadow;

                &:before {
                    display: block;
                    content: '';
                    width: 100%;
                    padding-top: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 6px;
                }

                & > span {
                    top: 50%;
                    left: 50%;
                    position: absolute;
                }
            }

            span.title {
                vertical-align: middle;
            }

            h3 {
                color: #292947;
                font-size: 14px;
                font-weight: 500;
                vertical-align: middle;
                margin: 10px 0;
            }

            span.artist {
                vertical-align: middle;
                text-align: left !important;
            }

            span.timer {
                vertical-align: middle;
                color: #cccbdd;
                font-weight: 600;
            }

            span.more {
                color: #c2c2cb;
                & > .dropdown {
                    border: none;
                }
            }

            a.favorite {
                width: 55px;
                font-size: 16px;

                i.icon-star-full {
                    margin-left: -23px;
                }
            }

            &:not(.header):hover, &.currentSongPlaying {
                border-radius: 6px;
                box-shadow: 0 2px 4px rgba(42,41,71,.1), 0 0 50px rgba(42,41,71,.05);

                span:first-of-type > a {
                    opacity: 1;
                }

                h3 {
                    // color: #681b8a;
                }

                span {
                    // color: #b12d86;
                }
            }

            &.header {
                & > * {
                    border: none !important;
                }

                span {
                    color: #aeadc1;
                    font-size: 11px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
            }

            &.artist {
                .cover {
                    border-radius: 50%;

                    img {
                        border-radius: 50%;
                    }
                }

                a {
                    width: auto;
                }
            }
        }
        // end element
    }
    // end list-horizontal
    &.list-grid {
        .element {
            @extend .col-lg-2;
            display: inline;
            width: 160px;
            // max-width: 160px;
            // margin: 0 15px;
            margin-bottom: 15px;
            // &:first-of-type {
            // 	margin-left: 0;
            // }
            // &:last-of-type {
            // 	margin-right: 0;
            // }
            .cover {
                position: relative;
                border-radius: 6px;
                box-shadow: $cover_shadow;
                margin-bottom: 30px;
                max-width: 210px;

                &:before {
                    display: block;
                    content: '';
                    width: 100%;
                    padding-top: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 6px;
                }

                .hoverContent {
                    @extend .d-flex;
                    @extend .align-items-center;
                    @extend .justify-content-around;
                    @extend .px-3;
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 6px;
                    opacity: 0;
                    transition: all .25s ease-in-out;
                    background-color: rgba(245, 62, 130, .9);
                    font-size: 16px;

                    & > a {
                        color: #ffffff !important;
                    }

                    i.icon-menu {
                        color: #ffffff !important;
                    }

                    .icon-play {
                        font-size: 20px;
                    }

                    .dropdown {
                        border: none;
                    }
                }

                &.exist {
                    .hoverContent {
                        a {
                            display: none !important;
                        }
                        opacity: 1;
                        &:after {
                            content: '\e817';
                            font-family: "fontello";
                            font-style: normal;
                            font-weight: normal;
                            speak: none;
                            font-variant: normal;
                            text-transform: none;
                            color: #ffffff;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 40px;
                        }
                    }
                }
            }

            span.title {
                color: #b9b8ce;
                font-size: 12px;
                font-weight: 700;
                display: block;
                margin: 16px 0;
                text-transform: uppercase;
            }

            h3 {
                color: #4f4e6c;
                font-size: 16px;
                line-height: 14px;
                font-weight: 500;
                line-height: 16px;
                margin: 16px 0;
                transition: all .25s ease-in-out;
            }

            span.artist {
                color: #9796b3;
                font-size: 14px;
                font-weight: 500;
                margin: 16px 0;
                transition: all .25s ease-in-out;
            }

            &.artist {
                @extend .text-center;

                .cover {
                    border-radius: 50%;

                    img {
                        border-radius: 50%;
                    }
                }

                .hoverContent {
                    border-radius: 50%;
                }
            }

            &.playlist {
                .cover {
                    border-radius: 26px;

                    img {
                        border-radius: 26px;
                    }
                }

                .hoverContent {
                    border-radius: 26px;
                }
            }

            &:hover {

                .hoverContent {
                    opacity: 1;
                }

                h3 {
                    color: #681b8a;
                }

                span.artist {
                    color: #b12d86 !important;
                }
            }
        }
    }

    .jumbtron {
        background: transparent;
        box-shadow: none !important;

        .wrapper {
            padding: 0;
            background: transparent;
        }

        h3 {
            color: #4f4d6b !important;
        }

        span {
            color: #8b8aa0 !important;

            &.light {
                color: #b5b5d0 !important;
            }
        }
    }
}

.noscrool {
	max-width: unset !important;
	max-height: unset !important;
	.content {
		max-width: unset !important;
		max-height: unset !important;
	}
}

.jumbtron {
    @extend .my-5;
    // background-image: url('../photos/album1.png'); // Dynamic changed
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    border-radius: 6px;
    box-shadow: 0px 30px 60px 0px rgba(250, 159, 158, 0.1);

    &.jumbtron-full {
        margin-top: 0px !important;
        margin-left: -3rem;
        margin-right: -3rem;
        border-radius: 0;

        .wrapper {
            border-radius: 0;
        }
    }

    .wrapper {
        border-radius: 6px;
        background: rgba(42, 41, 72, .85);
        padding: 40px;
    }

    .header {
        // @extend .pb-5;
        padding-bottom: 40px;

        span {
            @extend .text-uppercase;
            color: #f43d81;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .content {
        @extend .d-flex;

        .cover {
            position: relative;
            width: 200px;
            border-radius: 6px;
            box-shadow: $cover_shadow;
            overflow: hidden;

            &:before {
                display: block;
                content: '';
                width: 100%;
                padding-top: 100%;
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                right: 0;
                bottom: 0;
                border-radius: 6px;
                transform: translate(-50%, -50%);
            }
        }

        .info {
            @extend .d-flex;
            @extend .flex-column;
            @extend .justify-content-around;
            @extend .align-items-start;
            margin-left: 40px;
            padding: 10px 0;

            h3 {
                color: #fff;
                font-size: 20px;
                font-weight: bold;
                margin: 0;

                span.light {
                    font-size: 20px;
                    font-weight: 300;
                    color: #fff;
                }
            }

            span {
                color: #a7a5bd;
                font-size: 16px;
                font-weight: 500;

                span.year {
                    // color: #716f8f;
                    font-size: 16px;
                    font-weight: 400;
                }
            }

            span.small {
                color: #b0b0c5;
                font-size: 14px;
                font-weight: 600;

                span.light {
                    color: #7c7c90;
                    font-size: 14px;
                    font-weight: 400;

                    b {
                        font-weight: 600;
                    }
                }
            }

            span.type {
                color: #a9a8be;
                font-size: 13px;
                font-weight: 500;
            }

            .buttons > a {
                margin-right: 20px;
                color: #9e9eac;

                &:last-of-type {
                    margin-left: -8px;
                }
            }

            a {
                color: #a7a5bd;

                &:hover {
                    color: #ffffff;
                }
            }
        }
    }

    .button {
        border-color: rgba(255, 255, 255, .10);
        color: #9e9eac !important;

        &.button-f, &:hover {
            color: #fff !important;
        }
    }

    ul.inline {
        li {
            @extend .mr-4;
        }

        li > a {
            font-size: 16px;
            font-weight: 400;
            color: #868696 !important;
            position: relative;
            transition: color .25s ease-in-out;

            &:hover {
                color: #ffffff !important;
            }

            &.active {
                color: #ffffff !important;

                &:after {
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #f53d81;
                    bottom: -20px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
}

.openPlayer {
    #player {
        transform: translateY(0) !important;
    }
    .fillbox {
        margin-bottom: 90px !important;
    }
}

#player {
	@extend .d-flex;
	@extend .align-items-center;
	@extend .justify-content-between;
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 9999;

	max-height: 90px;

	box-shadow: 0 0 50px rgba(40,40,55,.05);
    background: #ffffff;
    transform: translateY(100%);

    transition: all .25s ease-in-out;

	* {
		z-index: 2;
    }


	audio {
		display: none;
	}

	.info {
		@extend .d-flex;
        padding: 24px;
        width: 22.5%;

		.title {
			// padding: 5px 0;
			@extend .d-flex;
			@extend .flex-column;
			@extend .justify-content-around;
            height: 43px;
            width: 100%;

            .scrollTitle {
                height: 20px;
                position: relative;
                overflow: hidden;

                h5 {
                    position: absolute;
                    white-space: nowrap;
                }
            }
		}

		h5 {
			color: #292947;
			font-size: 14px;
			font-weight: 600;
			margin: 0;
			line-height: 20px;
		}
		span {
			color: #9795b3;
			font-size: 14px;
			font-weight: 400;
			margin: 0;
			line-height: 0;
		}
		.cover {
			margin-right: 15px;
            position: relative;
            display: table;
			width: 43px;
			border-radius: 6px;
			box-shadow: $cover_shadow;
			&:before {
				display: block;
				content: '';
				width: 100%;
				padding-top: 100%;
			}
			img {
				width: 100%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				border-radius: 6px;
			}
		}
	}

	.progressBar {
		@extend .d-flex;
		@extend .align-items-center;
		@extend .justify-content-between;
		// @extend .w-50;
		width: 55% !important;

		#progress {
			@extend .d-flex;
			@extend .align-items-center;
			@extend .justify-content-between;
			@extend .w-100;

			height: 90px;
			position: relative;
			padding: 10px 0px;

			.activeProgress {
				display: none;
				position: absolute;
				height: 100%;
				z-index: 3;
				left: 0;

				&:before {
					content: '';
					position: absolute;
					left: 0;
					width: 5px;
					height: 100%;
					background-color: #f53e82;
				}

				&:after {
					content: '';
					position: absolute;
					left: 0;
					margin-left: -6px;
					bottom: 100%;
					border-radius: 50%;
					border-style: solid;
					border-width: 4px;
					border-color: #f53e82;
					box-sizing: border-box;
					box-shadow: 0px 10px 15px 0px rgba(245, 62, 130, 0.5);
					background: #ffffff;
					width: 17px;
					height: 17px;
				}
			}

			ul {
				@extend .d-flex;
				@extend .align-items-center;
				margin: 0;
				padding: 10px 0;
				width: 100%;
				height: 100%;
				position: relative;

				li {
					list-style-type: none;
					width: 4px;
					margin-right: 4px;
					// border-radius: 6px 6px 0 0;
					border-radius: 6px;
					background-color: #dedbe2;
					height: 6px;
					min-height: 20px;

					transition: background-color .25s ease-in-out;

					&.active {
						background-color: #f53e82;
					}
				}
			}
		}

		span.timer {
			@extend .px-4;
			color: #292947;
			font-size: 14px;
			font-weight: 700;
			opacity: .4;
			width: 95px;
			min-width: 95px;
		}
	}

	.navigation {
		@extend .d-flex;
		@extend .align-items-center;
		@extend .justify-content-end;
        padding-right: 24px;

        width: 22.5%;

		.element {
			@extend .d-flex;
			@extend .align-items-center;
			color: #b02d85;
			font-size: 16px;
			@extend .mx-2;
		}

		#volume {
			position: relative;
			width: 120px;
			height: 6px;
			background-color: rgba(42, 41, 71, 0.08);
			border-radius: 6px;
			margin-left: 15px;
			.val {
				position: absolute;
				top: 0;
				left: 0;
				background-color: #f53e82;
				height: 6px;
				border-radius: 6px;

				&:after {
					content: '';
					position: absolute;
					top: -3px;
					right: -6px;
					width: 12px;
					height: 12px;
					border-radius: 6px;
					background-clip: padding-box;
					background-color: #fff;
					box-shadow: 0 3px 6px rgba(50,29,43,.15), 0 0 15px rgba(40,40,55,.15);
				}
			}
		}
	}

	.playerButtons {
		@extend .d-flex;
		@extend .align-items-center;
		opacity: 0;
		padding: 10px 15px;
		border-radius: 6px;
		background-color: rgba(255, 255, 255, 0.9);
		box-shadow: 0px 2px 4px 0px rgba(42, 41, 71, 0.1);
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		transition: all .25s ease-in-out;
		z-index: 1100;

		a {
			color: rgba(42, 41, 71, 0.3);
			font-size: 14px;
			padding: 0 15px;
			transition: color .25s ease-in-out;
			&:hover {
				color: #f53e82;
			}
		}

		a:first-of-type {
			padding-left: 0;
		}
		a:last-of-type {
			padding-right: 0;
		}
	}

	.progressBar:hover {
		.playerButtons {
			opacity: 1;
		}
    }

    .demobox {
        position: absolute;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @extend .d-flex;
        @extend .justify-content-center;
        @extend .align-items-center;
        background-color: rgba($color: white, $alpha: 0.75);
        h1 {
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 0;
        }
    }
}

.fillbox {
    margin-bottom: 0px;
    transition: all .25s ease-in-out;
}

.light {
	font-weight: 300;
}

h3 {
	color: #4f4e6c;
	font-size: 30px;
}

p {
	color: #9796b1;
	font-size: 16px;
	line-height: 36px;
}

@include media-breakpoint-up(1680px)
{
	.list .header h2 {
		font-size: 30px !important;
	}

	// .list-vertical {
	// 	.element {
	// 		span.title {
	// 			font-size: 16px !important;
	// 			font-weight: 600 !important;
	// 			margin: 20px 0 !important;
	// 		}
	// 		h3 {
	// 			font-size: 18px !important;
	// 			font-weight: 600 !important;
	// 			margin: 20px 0 !important;
	// 		}
	// 		span.artist {
	// 			font-size: 18px !important;
	// 			font-weight: 600 !important;
	// 			margin: 20px 0 !important;
	// 		}
	// 	}
	// }
}

.foregroundContainer {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	background-color: rgba(255, 255, 255, .9);

	.close {
		@extend .m-5;
		i.icon-close {
			font-size: 48px;
			color: rgba(0, 0, 0, .3);
			transition: color .25s ease-in-out;
		}
	}

	.container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		i.icon-playlist-add {
			font-size: 32px;
		}
	}
}

.panel {
	background-color: #fff;
	box-shadow: 0 30px 60px rgba(118,117,145,.1), 0 0 25px rgba(42,41,71,.04);

	.box {
		position: relative;
		border-radius: 6px;
		margin-bottom: 30px;
		max-width: 210px;
		border: 2px solid rgba(42,41,71,.08);
		&:before {
			display: block;
			content: '';
			width: 100%;
			padding-top: 100%;
		}
		img {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 6px;
		}

		a {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: rgba(0, 0, 0, .3);
			transition: color .25s ease-in-out;
			font-size: 40px;

			&:hover {
				color: $pink;
			}
		}
	}

	h6 {
		@extend .sbold;
		color: #b4b3c1;
		font-size: 12px;
	}

	.form-group {
		label {
			color: #c2c2cc;
			font-size: 10px;
			@extend .bold;
		}

		input {
			border: none;
			box-shadow: none;
			color: #858497;
			font-size: 24px;
			padding: 0;
		}

		textarea {
			&.animated {
    			-webkit-transition: height 0.2s;
				-moz-transition: height 0.2s;
				transition: height 0.2s;
			}
			border: none;
			box-shadow: none;
			padding: 0;
			color: #858497;
			font-size: 16px;
			margin-bottom: -25px;
			@extend .regular;
		}
	}

	.form-check {
		input {
			display: none;
		}

		label {
			@extend .medium;
			color: #858497;
			font-size: 14px;

			&:before {
				content: '';
				width: 14px;
				height: 14px;
				background-color: #ffffff;
				border: solid 3px rgba(42,41,71,.1);
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				transition: all .25s ease-in-out;
			}
		}

		input[type="radio"]:checked + label:before {
			border-color: #ffffff;
			background-color: $pink;
		}
	}
}

.dark-container {
	background-color: #191929;
	color: #ffffff;

	.menu-container {
		background: #14141f;
		box-shadow: 0 30px 60px rgba(0,0,0,.08), 0 0 75px rgba(0,0,0,.1);
		padding: 0 !important;
		position: fixed !important;

		a.logo {
			width: 100%;
			display: block;
			text-align: center;
		}

		& > .menu-container {
			// padding: 30px 0 !important;
		}

		& > .menu-container > * {
			padding: 0 30px !important;
		}

		ul.menu {
			padding: 0 !important;

			li {
				padding: 15px 0;
				a {
					@extend .d-flex;
					@extend .align-items-center;
					padding: 10px 20px;
					margin: -10px 0;
					border-left: solid 3px;
					border-color: transparent;
					color: #9795b3;
					text-transform: uppercase;
					font-size: 12px;
					font-weight: 600;
					i[class^="icon-"] {
						font-size: 19px;
						color: #353453;
					}
					&.active {
						i[class^="icon-"] {
							color: #605f7b;
						}
						color: #ffffff;
						border-color: $pink;
					}
				}
			}
		}
	} // end menu container

	.button {
		border-color: rgba(70,69,94,.5);
		border-width: 1px;
		color: #ffffff !important;
		opacity: 1;

		&.button-noborder > i[class^="icon-"] {
			text-shadow: 0 5px 10px rgba(245,62,130,.5);
			&:hover > i[class^="icon-"] {
				text-shadow: unset;
			}
		}
	}

	img.avatar {
		width: 39px;
		height: 39px;
		border-radius: 50%;
	}

	.panel {
		background: #13131f;
		padding: 33px;

		border-radius: 0;

		&.panel-bordered {
			@extend .py-4;
			background: transparent;
			border: 1px solid rgba(70,69,94,.3);
			box-shadow: none;
		}

		p {
			font-size: 14px;
			font-weight: 500;
			margin: 0;
		}

		hr {
			border-color: rgba(255, 255, 255, .05);
			margin-left: -33px;
			margin-right: -33px;
		}

		input {
			color: #fff;
			font-size: 14px;
			font-weight: 600;
		}

	} // End panel

	h5 {
		color: #fff;
		font-size: 18px;
		font-weight: 500;
		// margin-bottom: 25px;
		margin: 0;

		&.title {
			font-size: 14px;
		}

		&.dark {
			color: #5b5b79;
		}
	}

	h6 {
		@extend .text-uppercase;
		color: #5b5b79;
		font-size: 12px;
		font-weight: 600;
		margin-bottom: 25px;
	}

	ul.menuHorizontal {
		margin-left: -33px !important;
		margin-right: -33px !important;
		padding-left: 33px !important;
		padding-right: 33px !important;
		border-bottom: solid 1px rgba(255, 255, 255, .05);
		@extend .m-0;
		li {
			border-bottom: solid 4px transparent;
			h5 {
				color: #585772;
				margin-bottom: 25px;
			}
		}
		li.active {
			border-bottom-color: $pink;
			h5 {
				color: #ffffff;
			}
		}
	}

	.borderedBox {
		@extend .d-flex;
		@extend .flex-column;
		@extend .align-items-center;
		@extend .justify-content-between;
		border: solid 2px rgba(255, 255, 255, .05);
		min-height: 130px;
		height: 100%;

		transition: border-color .25s ease-in-out;

		&:hover {
			border-color: $pink;
		}
	}

	ul.activity {
		margin: 0;
		padding: 0;
		li {
			list-style-type: none;
			margin: 0;
			padding: 0;
			padding-bottom: 33px;
			padding-left: 21px;
			border-left: solid 2px #2f2f43;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				left: -10px;
				top: 0;
				width: 18px;
				height: 18px;
				border: 1px solid rgba(70,69,94,.5);
				background-color: #191929;
				border-radius: 10px;
				background-clip: padding-box;
			}

			&:after {
				content: '';
				position: absolute;
				left: -4px;
				top: 6px;
				width: 6px;
				height: 6px;
				border-radius: 3px;
				background-clip: padding-box;
				background-color: #f53d81;
			}

			&:last-of-type {
				border-left-color: transparent;
			}

			p {
				color: #fff;
				font-size: 14px;
				font-weight: 600;
				margin: 0;
				line-height: 17px;
				margin-bottom: 20px;
			}
			span {
				color: #77778d;
				font-size: 14px;
				font-weight: 500;
			}
		}
	}
}

.stepline {
    text-align: center;
    margin-bottom: 1rem;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(177, 180, 196, 0.3);
    }

    span {
        color: #9796b1;
        background-color: #ffffff;
        position: relative;
        z-index: 1;
        $padding: 25px;
        padding-left: $padding;
        padding-right: $padding;
    }
}