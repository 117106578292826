#ErrorBoundary {
  min-height: 100vh;
  display: flex;

  #BACKGROUND {
    position: fixed;
    top: 0;
    z-index: -1;
  }

  .cls-1 {
    fill: #f5f5f9;
    fill-rule: evenodd;
    opacity: 0.6;
  }
}