@import '../../css/bs/bootstrap';

body {
  background-image: none !important;
  background-color: #f7f8fc;
}

.adminRelease {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 10px 20px 0px rgba(107, 113, 129, 0.05);
  overflow: auto;
  margin-bottom: 30px;

  .infoPanel {
    padding: 40px;
  }

  .cover {
    width: 100% !important;
    max-width: 100% !important;
    box-shadow: unset;
    position: relative;
    border-radius: 6px;
    overflow: hidden;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 100%;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 0;
      bottom: 0;
      border-radius: 6px;
      height: 100%;
      width: auto;
      transform: translate(-50%, -50%);
    }
  }

  .hr {
    margin: 40px 0 !important;
    width: 100%;
    height: 1px;
    background: rgba(182, 190, 211, .3);
  }

  p {
    font-size: 16px;
    color: rgb(98, 96, 135);
    line-height: 28px;
    margin: 0;
  }

  a[class^="icon-"] {
    font-size: 20px !important;
  }

  small {
    font-size: 12px;
    color: rgb(116, 114, 152);
    line-height: 28px;
  }
}

.adminEditRelease {
  .cover-album {
    background: #f6f7fa;
    width: 100%;
    max-width: 100%;
    box-shadow: unset;

    [class^="icon-"] {
      font-size: 48px;
      color: #d8d9e3;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .panel{
    .panel-header{
      align-items: flex-start !important;
      flex-direction: column !important;
      margin-bottom: 40px;
      padding-bottom: 40px;
      border-bottom: solid 1px rgba(182,190,211,0.3);
    }
  }
  .content-row{
    display: flex !important;
  }
  .cover {
    position: relative;
    border-radius: 6px;
    overflow: hidden;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 100%;
    }

    img{
      position:absolute;
      top: 50%;
      left: 50%;
      right:0;
      bottom:0;
      border-radius:6px;
      height:100%;
      width:auto;
      transform:translate(-50%, -50%);
    }
  }
  .cover-album {
    background: #f6f7fa;
    width: 100%;
    max-width: 100%;
    box-shadow: unset;
  }
  .input-group{
    @extend .d-flex;
    border-bottom:0 !important;

    &.input-group-prepend {
      flex-wrap: nowrap;
      flex-direction: row;
    }

    .input-group-prepend {
      border-style: solid;
      border-width: 1px;
      border-color: rgba(70, 69, 94, .1);
      background-color: rgba(255, 255, 255, 0);
      font-size: 14px;
      line-height: 14px;
      color: rgb(98, 96, 135);
      text-align: left;
      border-radius: 100px 0 0 100px;
      padding: 15.5px 15px;
      border-right: none;
      box-shadow: none;
      width: unset;

      a.btn {
        padding: 0;
      }
    }
    .input{
      border-style:solid;
      border-width:1px;
      border-color:rgba(70,69,94,0.1);
      background-color:rgba(255,255,255,0);
      font-size:14px;
      line-height:14px;
      color:#626087;
      text-align:left;
      border-radius:100px;
      padding:20px 30px;
      width:100%;
      box-shadow:none;
    }
    .btn{
      background: transparent;
      box-shadow: none;
      width: 100%;
      text-align: left;
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 14px;
      color: #626087;
      text-align: left;
      padding: 20px 0;
      position: relative;
      border: none;
    }

    .checkbox{
      border-color: transparent;
      position: relative;
      input{
        display:none;
      }
      label{
        margin: 0;
        cursor: pointer;
        color: #7c7ba9;
      }
      label:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        border-style: solid;
        border-width: 2px;
        border-color: rgba(141, 153, 174, .2);
        border-radius: 4px;
      }
      label:after {
        content: '\e817';
        font-family: "fontello";
        font-style: normal;
        font-weight: normal;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        margin-right: .2em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        margin-left: .2em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
        left: 0;
        opacity: 0;
        transition: opacity .25s ease-in-out;
        top: 50%;
        transform: translateY(-50%);
      }
      &.checkboxOnly {
        width: unset;
        label:before {
          left: 50%;
          transform: translate(-50%, -50%);
        }
        label:after {
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0;
        }
      }

      input:checked + label:after {
        opacity: 1;
      }
    }
  }
}

.adminpanel {

  @extend .d-flex;

  .logo {
    background-image: url('../../img/logo.png');
    width: 42px;
    height: 48px;
  }

  .titleColor {
      color: inherit !important;
  }

  .menu {
    background-color: #ffffff;
    box-shadow: 5px 0px 75px 0px rgba(34, 41, 60, 0.05);
    min-height: 100vh;
    width: 90px;
    @extend .d-flex;
    @extend .flex-column;
    @extend .align-items-center;
    @extend .justify-content-between;

    padding: 15px 0;

    [class^="icon-"] {
      font-size: 30px;
    }

    a {
      color: #b0b5d0;

      padding: 15px 0;
      text-align: center;

      &:hover {
        color: #f53e82;
        span {
          color: inherit !important;
        }
      }
    }

    .navlinks {
      a {
        color: #b0b5d0;

        padding: 15px 0;
        text-align: center;
        border-left-style: solid;
        border-left-width: 5px;
        border-left-color: transparent;
        transition: all .25s ease-in-out;

        &:hover {
          color: #f53e82;
          border-left-color: #f53e82;
          span {
            color: inherit !important;
          }
        }

        &.active {
          color: #f53e82;
          border-color: #f53e82;
          background-color: #fee2ec;
        }
      }
    }
  }

  .body {
    width: 100%;
    max-height: 100vh;
    overflow-y: auto;
    padding: 30px 0;
  }

  .badge {
    border-left: solid 3px #a1a1b23b;
    border-radius: 0;
    padding: 30px;
    text-align: left;
    small {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  .row > .col-lg-6:first-of-type > .panel {
    margin-right: 0;
  }
  .row > .col-lg-6:last-of-type > .panel {
    margin-left: 0;
  }

  .panel {
    margin: 0 -60px;
    padding: 60px;
    border-radius: 4px;

    small {
      font-size: 14px;
    }

    h5 {
      font-size: 18px;
    }

    * {
      color: inherit;
    }

    &.panel-pink {
      background-color: #f53e82;
      color: #ffffff;

      .hightlight {
        color: #000000;
      }

      .btn:hover {
        border-color: #681b8a;
        color: #681b8a;
      }
    }

    &.panel-purple {
      background-color: #681b8a;
      color: #ffffff;

      .hightlight {
        color: #f53e82;
      }

      .btn:hover {
        border-color: #f53e82;
        color: #f53e82;
      }
    }

    &.panel-pink, &.panel-purple {
      .btn {
        border: solid 2px rgba(255, 255, 255, .3);
        border-radius: 4px;
        font-size: 12px;
      }
    }
  }

  .nav {
    a {
      font-size: 18px;
      color: rgb(180, 179, 203);
      line-height: 36px;
      &.active {
        color: #2a2947;
      }
    }
    .btn {
      background: transparent;
      font-size: 12px;
      color: rgb(185, 185, 198);
      line-height: 12px;
      padding: 15px 20px;
      outline: none;
      box-shadow: none;
      &.active {
        background-color: #fee2ec;
        color: #f53e82;
      }
    }
    .nav-item:first-of-type {
      a {
        padding-left: 0;
      }
    }
  }

  .btn {
    font-size: 12px;
    line-height: 12px;
    padding: 15px 20px;
    outline: none;
    box-shadow: none;
    font-weight: 500;
    &.success {
      background-color: #e5f5e8;
      color: #4ebb63;
    }
    &.danger {
      background-color: #fee2ec;
      color: #f53e82;
    }
    &.mail {
      background-color: #f53e82;
      color: #FFFFFF;
    }
  }

  .graphContainer {
    position: relative;
    height: 350px;
  }

  .artistsList {
    .artist {
      color: #9998b8;
      @extend .mt-4;
      @extend .pb-4;
      border-bottom: solid 1px rgba(232, 232, 239, .75);
      p {
        line-height: 30px;
        margin-top: 15px;
      }
      &:last-of-type {
        border-bottom-color: transparent;
        padding-bottom: 0 !important;
      }
    }
  }

  .input-group {
    border-bottom: solid 2px rgba(226, 227, 234, .75);
    .form-control {
      padding: 0;
      color: #9b9ab7;
      font-size: 16px;
      font-weight: 500;
      line-height: 36px;
      border: none;
      outline: none;
      box-shadow: none;
    }

    .btn {
      background: transparent;
      box-shadow: none;
      padding-right: 0;
      color: #9b9ab7;

      [class^="icon-"] {
        font-size: 17px;
      }
    }
  }

  .message {
    .form-group {
      margin-bottom: 25px;
      position: relative;
      border-bottom: solid 2px rgba(226, 227, 234, .75);
      input, label {
        color: #9b9ab7;
        font-size: 16px;
        line-height: 36px;
        font-weight: 500 !important;
        padding: 0;
      }

      label {
        position: absolute;
        top: 0;
        left: 0;
        transition: all .25s ease-in-out;
      }

      input::placeholder {
        color: transparent;
      }

      input:not(:placeholder-shown) ~ label {
        font-size: 12px;
        transform: translateY(-20px);
        opacity: .5;
      }

      textarea {
        margin-bottom: 25px;
      }

      textarea::placeholder {
        color: transparent;
      }

      textarea ~ label {
        top: -6px;
      }

      textarea:not(:placeholder-shown) ~ label {
        font-size: 12px;
        transform: translateY(-20px);
        opacity: .5;
      }
    }
    .btn {
      background-color: #f53e82 !important;
      color: #ffffff;
    }
  }

  .table {
    margin-bottom: 0;
    thead {
      th {
        border: none;
        font-size: 12px;
        font-weight: 600;
        line-height: 36px;
        padding: 0;
        color: #b8b8d0;
      }
    }
    tbody {
      td {
        border: none;
        vertical-align: middle;
        color: #7e7d9c;
        font-size: 16px;
        font-weight: 500;
        line-height: 36px;
        padding: 15px 0;

        .avatar {
          border-radius: 4px;
          background-color: rgb(247, 248, 252);
          box-shadow: 0px 10px 30px 0px rgba(34, 41, 60, 0.25);
          width: 70px;
          height: 70px;
        }
      }
    }
  }
}

