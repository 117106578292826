@import 'opus';

body {
	font-family: 'Montserrat', sans-serif;
	background-color: #fdfeff;
	background-image: url('../img/containerBg.png');
	background-repeat: no-repeat;
	background-position: top center;
	background-size: 1519px 869px;
}

$pink: #f53e82;
// $panel-box-shadow: 0px 5px 20px 0px rgba(107,113,129,0.2);
$panel-box-shadow: 0px 10px 20px 0px rgba(107, 113, 129, 0.05);

a[class^="icon-"] {
	color: #c3c4cc !important;
	transition: color .25s ease-in-out !important;
	font-size: 24px !important;
	&:hover {
		color: $pink !important;
	}
}

.container-fluid > .row {
	@extend .d-flex;
}

.openPlayer {
    #player {
        transform: translateY(0) !important;
    }
    .fillbox {
        margin-bottom: 90px !important;
    }
}

.panelBody {

    .hightlight {
        color: $pink !important;
    }

    .rotate-45:before {
        transform: rotate(45deg);
    }

    .rotate-90:before {
        transform: rotate(90deg);
    }

    .rotate-135:before {
        transform: rotate(135deg);
    }

    .rotate-180:before {
        transform: rotate(180deg);
    }

    .rotate-270:before {
        transform: rotate(270deg);
    }

    .flex-grow {
        flex-grow: 1;
    }

    .container-fluid {
        padding: 0;
        z-index: 10000;
    }

    h2 {
        font-size: 30px;
    }

    h3 {
        font-size: 24px;
    }

    h5 {
        font-size: 16px;
        color: rgb(111, 109, 150);
    }

    .menu {
        max-width: 250px;
        width: 250px;
        min-height: 100vh;
        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 30px 20px rgba(79,85,96,0.05);
        color: rgb(93, 100, 117) !important;
        float: left;
        position: fixed;
        max-height: 100%;
        overflow-y: auto;

        small {
            font-size: 12px;
            color: rgb(93, 100, 117) !important;
        }

        h4 {
            font-size: 18px;
        }

        .light {
            color: #b9bdc7;
        }

        .container {
            padding: 0 30px;
        }

        .logo {
            margin-top: 60px;
            margin-bottom: 20px;
        }

        a {
            @extend .sbold;
            font-size: 12px;
            transition: color .25s ease-in-out;
        }
    }

    ul.menu-list {
        margin: 0;
        padding: 0;

        li.menu-element {
            margin: 0px 0;
            padding: 0;
            list-style-type: none;

            &:first-of-type {
                margin-top: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            a.menu-link {
                @extend .sbold;
                font-size: 12px;
                color: rgb(93, 100, 117);
                text-transform: uppercase;
                line-height: 1.2;
                position: relative;
                padding-left: 34px;
                transition: color .25s ease-in-out;
                display: inline-block;
                width:100%;
                padding-top: 20px;
                padding-bottom: 20px;

                i[class^="icon-"] {
                    font-size: 18px;
                    color: #b6bed3;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    margin-top: -2px;
                    transition: color .25s ease-in-out;
                }

                i.icon-artists {
                    left: -5px;
                }

                &:hover, &.active {
                    color: #681b8a;

                    i[class^="icon-"] {
                        color: $pink;
                    }
                }

                &.active:before {
                    content: '';
                    position: absolute;
                    left: -30px;
                    background: $pink;
                    width: 3px;
                    height: 58px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .content {
        margin-left: 250px;
    }

    a {
        font-size: 14px;
        @extend .sbold;
        transition: color .25s ease-in-out;
    }

    .user {
        @extend .d-flex;
        @extend .align-items-center;
        color: rgb(124, 124, 142);
        @extend .sbold;
        line-height: 1.2;
        font-size: 14px;

        img {
            border-radius: 50%;
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            width: 50px;
            height: 50px;
            @extend .mr-2;
        }
    }

    .button {
        font-weight: 800 !important;
        border: solid 1px rgba(70, 69, 94, .1) !important;
        color: rgb(148, 148, 174);
    }

    h5 {
        color: #2a2947;
        font-size: 16px;
        margin-bottom: 0;
    }

    .container {
        max-width: 1167px;
    }

    .hr {
        width: 100%;
        height: 1px;
        background: rgba(182, 190, 211, .3);
    }

    p {
        font-size: 14px;
        color: rgb(116, 114, 152);
        line-height: 28px;
    }

    .panel {
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        box-shadow: $panel-box-shadow;
        padding: 40px;
        border-style: solid;
        border-width: 1px;
        border-color: rgba(70, 69, 94, .1);

        p {
            color: rgb(116, 114, 152);
        }

        span {
            color: rgb(42, 41, 71);
        }

        small {
            color: rgb(184, 184, 205);
        }

        .hr {
            position: relative;

            &.hr-full:before {
                content: '';
                position: absolute;
                left: -40px;
                width: 40px;
                height: 1px;
                background: rgba(182, 190, 211, .3);
            }

            &.hr-full:after {
                content: '';
                position: absolute;
                right: -40px;
                width: 40px;
                height: 1px;
                background: rgba(182, 190, 211, .3);
            }
        }

        .content-row {
            @extend .d-flex;
        }

        .ligth {
            color: rgb(116, 114, 152);
        }

        small {
            font-size: 12px;
        }

        &.minp {
            padding: 26px 40px;
        }

        .panel-header {
            border-bottom: solid 1px rgba(182, 190, 211, .3);
            padding-bottom: 40px;
            margin-bottom: 40px;
            @extend .d-flex;
            @extend .align-items-center;

            &.panel-header-column {
                @extend .flex-column;
                @extend .align-items-start;
            }

            h5 {
                color: #2a2947;
                margin: 0;
            }

            ul.inline {
                li {
                    margin: 0 15px;

                    &:first-of-type {
                        margin-left: 0
                    }

                    &:last-of-type {
                        margin-right: 0
                    }

                    a {
                        color: rgb(172, 172, 197);
                        font-size: 12px;

                        &.active, &:hover {
                            color: rgb(116, 114, 152);
                        }
                    }
                }
            }
        }
        // settings
        .user-avatar {
            border-radius: 50%;
            width: 80px;
            height: 80px;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 100px;
                height: 100px;
                transform: translate(-50%, -50%);
                border: solid 1px rgba(217, 222, 233, .5);
                border-radius: 50%;
            }

            img {
                width: inherit;
                height: inherit;
                border-radius: 50%;
            }
        }

        .input-row {
            @extend .d-flex;

            & > *:first-of-type {
                @extend .mr-2;
            }

            & > *:last-of-type {
                @extend .ml-2;
            }
        }

        .input-group {
            @extend .d-flex;

            &.input-group-prepend {
                flex-wrap: nowrap;
                flex-direction: row;
            }

            .input-group-prepend {
                border-style: solid;
                border-width: 1px;
                border-color: rgba(70, 69, 94, .1);
                background-color: rgba(255, 255, 255, 0);
                font-size: 14px;
                line-height: 14px;
                color: rgb(98, 96, 135);
                @extend .sbold;
                text-align: left;
                border-radius: 100px 0 0 100px;
                padding: 15.5px 15px;
                border-right: none;
                box-shadow: none;
                width: unset;

                a.btn {
                    padding: 0;
                }
            }

            .label {
                font-size: 12px;
                color: rgb(177, 175, 198);
                @extend .bold;
                @extend .mb-2;
                text-transform: uppercase;
                line-height: 12px;
                text-align: left;
                padding: 0 30px;
            }

            .input {
                border-style: solid;
                border-width: 1px;
                border-color: rgba(70, 69, 94, .1);
                background-color: rgba(255, 255, 255, 0);
                font-size: 14px;
                line-height: 14px;
                color: rgb(98, 96, 135);
                @extend .sbold;
                text-align: left;
                border-radius: 100px;
                padding: 20px 30px;
                width: 100%;
                box-shadow: none;
                outline: none;

                &.checkbox {
                    border-color: transparent;
                    position: relative;

                    input {
                        display: none;
                    }

                    label {
                        margin: 0;
                        cursor: pointer;
                        color: rgb(124, 123, 169);
                    }

                    label:before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        width: 20px;
                        height: 20px;
                        border-style: solid;
                        border-width: 2px;
                        border-color: rgba(141, 153, 174, .2);
                        border-radius: 4px;
                    }

                    label:after {
                        content: '\e817';
                        font-family: "fontello";
                        font-style: normal;
                        font-weight: normal;
                        display: inline-block;
                        text-decoration: inherit;
                        width: 1em;
                        margin-right: .2em;
                        text-align: center;
                        font-variant: normal;
                        text-transform: none;
                        line-height: 1em;
                        margin-left: .2em;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        position: absolute;
                        left: 0;
                        opacity: 0;
                        transition: opacity .25s ease-in-out;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &.checkboxOnly {
                        width: unset;
                        label:before {
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        label:after {
                            left: 50%;
                            transform: translate(-50%, -50%);
                            margin: 0;
                        }
                    }

                    input:checked + label:after {
                        opacity: 1;
                    }
                }

                &.input-append {
                    border-radius: 0 100px 100px 0;
                    padding: 20px 15px;
                    width: 100%;
                }
            }

            .dropdown {
                box-shadow: none !important;
            }
        }

        table {
            thead {
                th {
                    border: none;
                    font-size: 12px;
                    color: rgb(166, 166, 192);
                    @extend .bold;
                    text-transform: uppercase;
                    padding: 12px 0px;
                }
            }

            td {
                @extend .medium;
                border: none;
                font-size: 14px;
                color: rgb(98, 96, 135);
                line-height: 28px;
                border-bottom: solid 1px rgba(182, 190, 211, .3);
                padding: 25px 0px;
                vertical-align: middle;
            }
            div {
                display: table-row;
            }
            div:last-of-type td {
                border: none;
            }
        }

        ul.activity {
            margin: 0;
            padding: 0;
            margin-top: 60px;

            li {
                margin: 0;
                padding: 0;
                padding-left: 27px;
                list-style-type: none;
                border-left: solid 1px rgba(227, 230, 239, .5);
                position: relative;

                &:last-of-type {
                    border-left: transparent;
                }

                &:before {
                    content: '';
                    position: absolute;
                    border-style: solid;
                    border-width: 1px;
                    border-color: rgba(217, 222, 233, .75);
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 1);
                    width: 18px;
                    height: 18px;
                    left: 0;
                    top: 0;
                    transform: translate(-50%, -50%);
                }

                &:after {
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                    background-color: rgb(245, 62, 130);
                    width: 6px;
                    height: 6px;
                    left: 0;
                    top: 0;
                    transform: translate(-50%, -50%);
                }

                div {
                    transform: translateY(-50%);
                    padding-bottom: 10px;
                }

                h5 {
                    font-size: 16px;
                    color: rgb(98, 96, 135);
                    line-height: 28px;
                }

                small {
                    font-size: 12px;
                    color: rgb(116, 114, 152);
                    line-height: 28px;
                }
            }
        }
    }

    .alert {
        padding: 10px 20px !important;
        border-radius: 4px !important;
        box-shadow: none !important;
        margin: 0;
        font-size: 14px;

        small {
            font-size: 10px;
            @extend .sbold;
            color: inherit;
        }

        i[class^="icon-"] {
            font-size: 19px;
        }

        a {
            color: inherit;
        }

        &.success {
            background: #dafadf;
            color: rgb(62, 162, 74);
        }

        &.warning {
            background: #feece3;
            color: rgb(255, 142, 103);
        }

        &.danger {
            background: #feeff2;
            color: rgb(250, 113, 139);
        }

        &.alert-progress {
            border-style: solid;
            border-width: 1px;
            border-color: rgb(236, 236, 239);
            border-radius: 4px;
            background: rgba(255, 255, 255, 0);
            color: rgb(115, 114, 150);
            position: relative;

            .progressbar {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 4px;
                background: #59ce66;
                transition: width .25s ease-in-out;
            }
        }
    }

    .modal {
        background: rgba(174, 174, 174, 0.67);
        z-index: 10000;

        .modal-dialog {
            pointer-events: all;
        }

        .input-row {
            margin: 0 -20px !important;
        }
    }

    .cover {
        position: relative;
        border-radius: 6px;
        box-shadow: 0px 20px 20px 0px rgba(250, 159, 158, 0.3);
        max-width: 60px;
        width: 60px;
        overflow: hidden;

        &:before {
            display: block;
            content: '';
            width: 100%;
            padding-top: 100%;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            right: 0;
            bottom: 0;
            border-radius: 6px;
            height: 100%;
            width: auto;
            transform: translate(-50%, -50%);
        }
    }

    .create-wallet {
        min-height: 100vh;
        padding-bottom: 164px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;

        .overlay {
            z-index: 10001;
        }

        .content-center {
            top: 50%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .text-center {
            background: #fff;
            padding-bottom: 164px;
        }

        .footer {
            position: fixed;
            bottom: 0;
            width: 100%;
            background-color: rgb(255, 255, 255);
            box-shadow: 0px -5px 20px 0px rgba(107,113,129,0.2);
        }

        input {
            background-color: rgb(255, 255, 255);
            box-shadow: $panel-box-shadow;
            font-size: 14px;
            color: rgb(98, 96, 135);
            @extend .sbold;
            line-height: 14px;
            text-align: left;
            border: none;
            border-radius: 100px;
            padding: 20px 30px;
            width: 570px;
            outline: none;

            &::placeholder {
                opacity: 1;
            }
        }

        .input-noStyle {
            background: none;
            border: none;
            margin: unset;
            padding: unset;
            box-shadow: unset;
        }

        ul.inline {
            li {
                font-size: 16px;
                color: rgb(156, 164, 183);
                border-bottom: solid 4px transparent;
                margin-right: 38px;
                padding: 53px 0;

                &.active {
                    border-color: $pink;
                    color: rgb(93, 100, 117);
                }
            }
        }

        .closeButton {
            font-size: 14px;
            color: rgb(148, 148, 174);
            transition: color .25s ease-in-out;

            @extend .sbold;

            [class^="icon-"] {
                font-size: 19px;
            }

            &:hover {
                color: $pink;
            }
        }

        .absolute-right {
            color: $pink;
            font-size: 55px;
            position: absolute;
            top: 30px;
            right: 56px;
        }

        .cover-album {
            background: #f6f7fa;
            width: 100%;
            max-width: 100%;
            box-shadow: unset;

            [class^="icon-"] {
                font-size: 48px;
                color: #d8d9e3;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .dropdown-element {
            border-width: 2px;
            border-color: rgba(182, 190, 211, .8);
            border-style: dotted;
            border-radius: 4px;
            position: relative;

            &.dropped {
                border: none;
            }

            h3 {
                color: rgb(42, 41, 71);
            }

            .panel-header {
                border: none;
                padding: 0;
            }

            .panel.offset {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
            }
        }
    }

    a:hover {
        text-decoration: none;
        color: #f53e82;
        span {
          color: #f53e82 !important;
        }
      }
} // end panel

.newReleaseSortableList {
    z-index: 11000;
    display: flex;
    td {
        @extend .medium;
        flex-grow: 1 !important;
        border: none;
        font-size: 14px;
        color: rgb(98, 96, 135);
        line-height: 28px;
        border-bottom: solid 1px rgba(182, 190, 211, .3);
        padding: 25px 0px;
        vertical-align: middle;
    }

    .input-group {
        @extend .d-flex;

        &.input-group-prepend {
            flex-wrap: nowrap;
            flex-direction: row;
        }

        .input-group-prepend {
            border-style: solid;
            border-width: 1px;
            border-color: rgba(70, 69, 94, .1);
            background-color: rgba(255, 255, 255, 0);
            font-size: 14px;
            line-height: 14px;
            color: rgb(98, 96, 135);
            @extend .sbold;
            text-align: left;
            border-radius: 100px 0 0 100px;
            padding: 15.5px 15px;
            border-right: none;
            box-shadow: none;
            width: unset;

            a.btn {
                padding: 0;
            }
        }

        .label {
            font-size: 12px;
            color: rgb(177, 175, 198);
            @extend .bold;
            @extend .mb-2;
            text-transform: uppercase;
            line-height: 12px;
            text-align: left;
            padding: 0 30px;
        }

        .input {
            border-style: solid;
            border-width: 1px;
            border-color: rgba(70, 69, 94, .1);
            background-color: rgba(255, 255, 255, 0);
            font-size: 14px;
            line-height: 14px;
            color: rgb(98, 96, 135);
            @extend .sbold;
            text-align: left;
            border-radius: 100px;
            padding: 20px 30px;
            width: 100%;
            box-shadow: none;

            &.checkbox {
                border-color: transparent;
                position: relative;

                input {
                    display: none;
                }

                label {
                    margin: 0;
                    cursor: pointer;
                    color: rgb(124, 123, 169);
                }

                label:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 20px;
                    height: 20px;
                    border-style: solid;
                    border-width: 2px;
                    border-color: rgba(141, 153, 174, .2);
                    border-radius: 4px;
                }

                label:after {
                    content: '\e817';
                    font-family: "fontello";
                    font-style: normal;
                    font-weight: normal;
                    display: inline-block;
                    text-decoration: inherit;
                    width: 1em;
                    margin-right: .2em;
                    text-align: center;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1em;
                    margin-left: .2em;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    left: 0;
                    opacity: 0;
                    transition: opacity .25s ease-in-out;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.checkboxOnly {
                    width: unset;
                    label:before {
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    label:after {
                        left: 50%;
                        transform: translate(-50%, -50%);
                        margin: 0;
                    }
                }

                input:checked + label:after {
                    opacity: 1;
                }
            }

            &.input-append {
                border-radius: 0 100px 100px 0;
                padding: 20px 15px;
                width: 100%;
            }
        }

        .dropdown {
            box-shadow: none !important;
        }
    }
}

.dropdown {
    border-style: solid;
    border-width: 1px;
    border-color: rgba(70, 69, 94, .1);
    background-color: rgba(255, 255, 255, 0);
    outline: none;
    border-radius: 100px;
    padding: 0 30px;

    button, button:focus, button:active {
        background: transparent;
        box-shadow: none;
        width: 100%;
        text-align: left;
        margin: 0;
        padding: 0;
        font-size: 14px;
        line-height: 14px;
        color: rgb(98, 96, 135);
        @extend .sbold;
        text-align: left;
        padding: 20px 0;
        position: relative;
        border: none;

        &:after {
            position: absolute;
            top: 50%;
            right: -15px;
            transform: translateY(-50%);
        }
    }

    .dropdown-menu {
        right: 60px;
        border-top: none;
        border-radius: 0 0 6px 6px;
        margin-top: 0px;
        border-color: rgba(70, 69, 94, .1);
        box-shadow: $panel-box-shadow;
    }
}

.data-info {
	color: rgb(93, 100, 117);

	& > .light {
		color: #b9bdc7 !important;
	}
}

.release {
	border-radius: 4px;
	background-color: rgb(255, 255, 255);
	box-shadow: 0px 10px 20px 0px rgba(107, 113, 129, 0.05);
	overflow: auto;
	margin-bottom: 30px;

    .overlay {
        background-color:  rgba(42, 41, 72, 0.85);
    }

    .overlayText {
        position: absolute;
        text-align: center;
        color: #f53e82;
        left: -50%;
        top: 15%;
        right:0;
        transform: rotate(-45deg);
        background-color: rgba(42, 41, 72, 0.85);
    }

	.infoPanel {
		padding: 40px;
	}

	.cover {
		width: 100% !important;
		max-width: 100% !important;
        box-shadow: unset;
    }


	.hr {
		margin: 40px 0 !important;
	}

	p {
		font-size: 16px;
		color: rgb(98, 96, 135);
		line-height: 28px;
		margin: 0;
	}

	a[class^="icon-"] {
		font-size: 20px !important;
	}

	small {
		font-size: 12px;
		color: rgb(116, 114, 152);
		line-height: 28px;
	}
}

.editRelease {
    .cover-album {
        background: #f6f7fa;
        width: 100%;
        max-width: 100%;
        box-shadow: unset;

        [class^="icon-"] {
            font-size: 48px;
            color: #d8d9e3;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.sortable {
    margin-top: 0 !important;
}

.wrapString {
    word-break: break-all;
}

.notFoundSite {
    h1 {
        font-size: 72px;
        color: rgb(245, 62, 130);
        font-weight: 800;
        text-align: center;
    }

    h2 {
        color: #2a2947 !important;
    }

    h4 {
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        color: #747298;
    }
}

.react-datepicker-popper {
    z-index: 3;
}

.Toastify__toast-container {
    z-index: 99999 !important;
}

.notFoundBox {
    background: #0c0538;
    border-radius: 6px;
    box-shadow: 0px 30px 60px 0px rgba(118, 117, 145, 0.1);
    width: 100%;
    padding: 60px;
    color: #fff;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: -8px;
        right: 0;
        bottom: 0;
        background: url('../img/notFoundGirlWithHighForehead.png');
        background-size: 100% auto;
        width: 770px;
    }

    h1 {
        line-height: auto;
    }

    p {
        color: #b8b7ce;
        line-height: auto;
    }
}



.elementSortable {
    width: 100%;
    @extend .d-table-row;
    height: 55px;
    margin: 15px 0;
    transition: box-shadow .25s ease-in-out;

    &.sortOnDrag {
        display: inline-table !important;
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(42,41,71,.1), 0 0 50px rgba(42,41,71,.05);
        background-color: #ffffff;
    }

    span {
        color: #9795b3;
        font-size: 14px;
        font-weight: 500;

        &:first-of-type {
            @extend .text-center;
            position: relative;

            &:not(.coverContent) {
                /*width: 40px;*/
                /*text-align: right !important;*/
            }

            a {
                @extend .d-flex;
                @extend .align-items-center;
                @extend .justify-content-center;
                background: #ffffff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 32px;
                height: 32px;
                color: rgba(121, 123, 139, .45);
                border: 3px solid rgba(131,133,149,.15);
                border-radius: 32px;
                opacity: 0;
                transition: all .25s ease-in-out;
                font-size: 8px;

                i {
                    margin-left: .8px;
                }

                &:hover {
                    border-color: transparent;
                    background-color: $pink;
                    color: #fff;
                }
            }
        }

        &:last-of-type {
            @extend .text-center;
        }

        &:nth-last-child(2) {
            @extend .text-right;
        }
    }

    a:hover > span {
        color: #292947 !important;
    }

    span, h3 {
        @extend .d-table-cell;
        vertical-align: middle;
        transition: all .25s ease-in-out;
    }

    a {
        display: table-cell;
        vertical-align: middle;
        width: 32%;

        span {
            width: 100%;
        }
    }

    .coverContent {
        @extend .d-table-cell;
        width: 80px;
        height: 80px;
        padding-left: 10px;
        vertical-align: middle;
    }

    .cover {
        // @extend .d-table-cell;
        // vertical-align: middle;
        position: relative;
        width: 60px;
        border-radius: 6px;
        box-shadow: $cover_shadow;

        &:before {
            display: block;
            content: '';
            width: 100%;
            padding-top: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 6px;
        }

        & > span {
            top: 50%;
            left: 50%;
            position: absolute;
        }
    }

    span.title {
        vertical-align: middle;
    }

    h3 {
        color: #292947;
        font-size: 14px;
        font-weight: 500;
        vertical-align: middle;
        margin: 10px 0;
    }

    input {
        color: #292947;
        font-size: 14px;
        font-weight: 500;
        vertical-align: middle;
        margin: 10px 0;
        padding: 0;
        border: none;
        background: transparent;
        outline: unset;
    }

    span.artist {
        vertical-align: middle;
        text-align: left !important;
    }

    span.timer {
        vertical-align: middle;
        color: #cccbdd;
        font-weight: 600;
    }

    div.dropdown {
        @extend .d-table-cell;
        vertical-align: middle;
        transition: all .25s ease-in-out;
    }

    span.more {
        color: #c2c2cb;
    }

    a.favorite {
        width: 55px;
        font-size: 16px;

        i.icon-star-full {
            margin-left: -23px;
        }
    }

    &:not(.header):hover, &.active {
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(42,41,71,.1), 0 0 50px rgba(42,41,71,.05);

        span:first-of-type > a {
            opacity: 1;
        }

        h3 {
            // color: #681b8a;
        }

        span {
            // color: #b12d86;
        }
    }

    &.header {
        & > * {
            border: none !important;
        }

        span {
            color: #aeadc1;
            font-size: 11px;
            font-weight: 600;
            text-transform: uppercase;
        }
    }

    &.artist {
        .cover {
            border-radius: 50%;

            img {
                border-radius: 50%;
            }
        }

        a {
            width: auto;
        }
    }
}
// end element

.modal {
    background: #353452cc;
    z-index: 100000;

    .panel {
        border-radius: 10px;
    }
}

.panel-wallet {
    a {
        color: #9494ae;
    }

    h5, h6, small {
        color: rgb(98, 96, 135);
    }

    .icon-arrow-down {
        font-size: 25px;
        &::before {
            transition: transform .25s ease-in-out;
        }
    }

    [aria-expanded="true"] > .icon-arrow-down::before {
        transform: rotate(180deg);
    }
}

.nav-tabs {
    margin-bottom: 20px;
    .nav-item {
        margin-bottom: 0;

        &:first-of-type > a {
            margin-left: 0;
        }

        a {
            border: none;
            font-size: 18px;
            color: rgb(189, 189, 199);
            line-height: 28px;
            border-bottom: solid 3px transparent;
            @extend .medium;

            padding: 8px 0;
            margin: 0 18px;

            transition: all .25s ease-in-out;

            &:hover {
                border-color: transparent;
            }

            &.active {
                border-color: #f53e82;
                color: #626087;
            }
        }
    }
}