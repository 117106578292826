.scrollComponent {
	overflow: hidden;
	position: relative;
}

.scrollComponent > .wrapper {
	overflow: scroll;
	margin-right: -17px;
	margin-bottom: -17px;
}

.scrollComponent > .wrapper .dropdown-item {
	white-space: inherit;
}

.scrollComponent .scroll-y {
	position: absolute;
	top: 0px;
	bottom: 12px;
	right: 6px;
	border-radius: 3px;

	background-color: rgba(233, 233, 236, .5);
	opacity: 0;
	width: 6px;

	transition: opacity .25s ease-in-out;
}

.scrollComponent .scroll-y-handler {
	background-color: rgb(210, 210, 222);
	width: 100%;
	top: 0;
	border-radius: 5px;
	max-height: 100% !important;

	transition: top .25s ease-in-out;
}

.scrollComponent .scroll-x {
	position: absolute;
	bottom: 6px;
	left: 0px;
	right: 12px;
	border-radius: 3px;

	background-color: rgba(233, 233, 236, .5);
	opacity: 0;
	height: 6px;

	transition: opacity .25s ease-in-out;
}

.scrollComponent .scroll-x-handler {
	background-color: rgb(210, 210, 222);
	height: 100%;
	left: 0;
	border-radius: 5px;
	max-width: 100% !important;

	transition: top .25s ease-in-out;
}

