@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?69822275');
  src: url('../font/fontello.eot?69822275#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?69822275') format('woff2'),
       url('../font/fontello.woff?69822275') format('woff'),
       url('../font/fontello.ttf?69822275') format('truetype'),
       url('../font/fontello.svg?69822275#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?69822275#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-sound:before { content: '\e800'; } /* '' */
.icon-repeat:before { content: '\e801'; } /* '' */
.icon-artists-1:before { content: '\e802'; } /* '' */
.icon-explore:before { content: '\e803'; } /* '' */
.icon-folder:before { content: '\e804'; } /* '' */
.icon-playlist:before { content: '\e805'; } /* '' */
.icon-search:before { content: '\e806'; } /* '' */
.icon-songs:before { content: '\e807'; } /* '' */
.icon-stats-1:before { content: '\e808'; } /* '' */
.icon-dot:before { content: '\e809'; } /* '' */
.icon-music-folder:before { content: '\e80a'; } /* '' */
.icon-arrow-down:before { content: '\e80b'; } /* '' */
.icon-arrow-up:before { content: '\e80c'; } /* '' */
.icon-arrow-right:before { content: '\e80d'; } /* '' */
.icon-play:before { content: '\e80e'; } /* '' */
.icon-star:before { content: '\e80f'; } /* '' */
.icon-menu:before { content: '\e810'; } /* '' */
.icon-shuffle:before { content: '\e811'; } /* '' */
.icon-next:before { content: '\e812'; } /* '' */
.icon-pause:before { content: '\e813'; } /* '' */
.icon-previous:before { content: '\e814'; } /* '' */
.icon-star-full:before { content: '\e815'; } /* '' */
.icon-camera:before { content: '\e816'; } /* '' */
.icon-ok:before { content: '\e817'; } /* '' */
.icon-warning:before { content: '\e818'; } /* '' */
.icon-security:before { content: '\e819'; } /* '' */
.icon-account:before { content: '\e81a'; } /* '' */
.icon-mail:before { content: '\e81b'; } /* '' */
.icon-wallet:before { content: '\e81c'; } /* '' */
.icon-dashboard:before { content: '\e81d'; } /* '' */
.icon-siatka:before { content: '\e81e'; } /* '' */
.icon-up-1:before { content: '\e81f'; } /* '' */
.icon-share:before { content: '\e820'; } /* '' */
.icon-lista:before { content: '\e821'; } /* '' */
.icon-playlist-add:before { content: '\e822'; } /* '' */
.icon-close:before { content: '\e823'; } /* '' */
.icon-add-picture:before { content: '\e824'; } /* '' */
.icon-play-circled2:before { content: '\e825'; } /* '' */
.icon-eye-off:before { content: '\e826'; } /* '' */
.icon-eye-on:before { content: '\e827'; } /* '' */
.icon-stats:before { content: '\e828'; } /* '' */
.icon-add:before { content: '\e829'; } /* '' */
.icon-heart:before { content: '\e82a'; } /* '' */
.icon-heart-empty:before { content: '\e82b'; } /* '' */
.icon-plus:before { content: '\e82c'; } /* '' */
.icon-close-circle:before { content: '\e834'; } /* '' */
.icon-compass:before { content: '\e840'; } /* '' */
.icon-download:before { content: '\e847'; } /* '' */
.icon-edit-pencil-symbol:before { content: '\e848'; } /* '' */
.icon-heart-outline:before { content: '\e85a'; } /* '' */
.icon-info-circle:before { content: '\e85d'; } /* '' */
.icon-musical-note:before { content: '\e86b'; } /* '' */
.icon-note:before { content: '\e86e'; } /* '' */
.icon-prohibition-signal:before { content: '\e87c'; } /* '' */
.icon-user:before { content: '\e89f'; } /* '' */
.icon-cd:before { content: '\e8a3'; } /* '' */
.icon-accept:before { content: '\e8ac'; } /* '' */
.icon-envelope:before { content: '\e8b3'; } /* '' */
.icon-artists:before { content: '\e8dd'; } /* '' */
.icon-dj:before { content: '\e95c'; } /* '' */
.icon-logout:before { content: '\e95d'; } /* '' */
.icon-audio-file:before { content: '\e95e'; } /* '' */
.icon-bar-chart:before { content: '\e95f'; } /* '' */
.icon-admin-search:before { content: '\e960'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-menu-1:before { content: '\f0c9'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-dot-circled:before { content: '\f192'; } /* '' */
.icon-google:before { content: '\f1a0'; } /* '' */
.icon-medium:before { content: '\f23a'; } /* '' */
.icon-reddit-alien:before { content: '\f281'; } /* '' */
.icon-telegram:before { content: '\f2c6'; } /* '' */
.icon-facebook-squared:before { content: '\f308'; } /* '' */